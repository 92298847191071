import { http } from '@/lib/http'
import { AutomationRuleListType } from '#/EntityTypes'
import {
  AssetsItemType,
  AssetsListType,
  PriceProviderType,
  DerivativeItemType,
  PriceProviderItemType,
  FutureRawDataListType,
  OptionsRawDataListType,
  ValidatorRawDataListType,
  FutureAdjustmentListType,
  OptionsAdjustmentListType,
  ValidatorAdjustmentListType
} from '#/ValuationTypes'

export default {
  /**
   * 获取Crypto Summary列表
   * @param {string} entityId 主体id
   * @param {Object} params
   * @property {string} params.beginDate 开始日期
   * @property {string} params.endDate 结束日期
   */
  getCryptoSummaryList: (entityId: string, params: object) => {
    return http.request('get', `entity/${entityId}/valuation/crypto/summary`, { params })
  },
  /**
   * 获取Crypto Acquisition列表
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} [data.type] 类型
   * @property {string} data.currency 币种
   * @property {number} data.page 页码
   * @property {number} data.limit 每页数量
   */
  getCryptoAcquisitionList: (entityId: string, data: object) => {
    return http.request('post', `entity/${entityId}/valuation/crypto/acquisition`, { data })
  },
  /**
   * 获取Crypto Lots列表
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.type 类型
   * @property {string} data.currency 币种
   * @property {string} data.remain
   * @property {number} data.page 页码
   * @property {number} data.limit 每页数量
   */
  getCryptoLotList: (entityId: string, data: object) => {
    return http.request('post', `entity/${entityId}/valuation/crypto/lot`, { data })
  },
  /**
   * 获取Crypto Disposal列表
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.type 类型
   * @property {string} data.currency 币种
   * @property {number} data.page 页码
   * @property {number} data.limit 每页数量
   */
  getCryptoDisposalList: (entityId: string, data: object) => {
    return http.request('post', `entity/${entityId}/valuation/crypto/disposal`, { data })
  },
  /**
   * 获取Crypto adjusting列表
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.type 类型
   * @property {number} data.page 页码
   * @property {number} data.limit 每页数量
   */
  getCryptoAdjustingList: (entityId: string, data: object) => {
    return http.request('post', `entity/${entityId}/valuation/crypto/adjusting`, { data })
  },
  /**
   * 获取Fiat Summary列表
   * @param {string} entityId 主体id
   * @param {Object} params
   * @property {string} params.beginDate 开始日期
   * @property {string} params.endDate 结束日期
   */
  getFiatSummaryList: (entityId: string, params: object) => {
    return http.request('get', `entity/${entityId}/valuation/fiat/summary`, { params })
  },
  /**
   * 获取Fiat Acquisition列表
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.type 类型
   * @property {number} data.page 页码
   * @property {number} data.limit 每页数量
   */
  getFiatAdjustingList: (entityId: string, data: object) => {
    return http.request('post', `entity/${entityId}/valuation/fiat/adjusting`, { data })
  },
  /**
   * 获取Broker Purchase列表
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.currency 币种
   * @property {string} data.sortByDateDirection 排序
   * @property {number} data.page 页码
   * @property {number} data.limit 每页数量
   */
  getBrokerPurchaseList: (entityId: string, data: object) => {
    return http.request('post', `entity/${entityId}/valuation/broker/purchase`, { data })
  },
  /**
   * 获取Broker Inventory列表
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.currency 币种
   * @property {number} data.page 页码
   * @property {number} data.limit 每页数量
   */
  getBrokerInventoryList: (entityId: string, data: object) => {
    return http.request('post', `entity/${entityId}/valuation/broker/inventory`, { data })
  },
  /**
   * 获取Broker Sale列表
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.currency 币种
   * @property {string} data.sortByDateDirection 排序
   * @property {number} data.page 页码
   * @property {number} data.limit 每页数量
   */
  getBrokerSaleList: (entityId: string, data: object) => {
    return http.request('post', `entity/${entityId}/valuation/broker/sale`, { data })
  },
  /**
   * 获取Broker Sale详情
   * @param {string} entityId 主体id
   * @param {string} saleId 销售id
   */
  getBrokerSaleDetail: (entityId: string, saleId: string) => {
    return http.request('get', `entity/${entityId}/valuation/broker/sale/${saleId}`)
  },
  /**
   * 获取Sale中Inventory候选列表
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.saleId 销售id
   * @property {number} data.page 页码
   * @property {number} data.limit 每页数量
   */
  getInventoryCandidateList: (entityId: string, data: object) => {
    return http.request('post', `entity/${entityId}/sale/inventory/candidate`, { data })
  },
  /**
   * Sale成本切换为手动指定
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.saleId 销售id
   * @property {string[]} [data.inventoryIds] 库存ids
   */
  switchSaleInventoryToManual: (entityId: string, data: object) => {
    return http.request('post', `entity/${entityId}/sale/inventory/match/manual`, { data })
  },
  /**
   * Sale成本切换为规则匹配
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.saleId 销售id
   */
  switchSaleInventoryToRule: (entityId: string, data: object) => {
    return http.request('post', `entity/${entityId}/sale/inventory/match/rule`, { data })
  },
  /**
   * 更改公允价值计算方法
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.fairValueCostBasisMethodology 公允价值计算方法 FIFO/COST_AVERAGE
   */
  changeFairValueCostBasisMethodology: (entityId: string, data: object) => {
    return http.request('put', `entity/${entityId}/fairValueCostBasisMethodology`, { data })
  },
  /**
   * 更改库存计算方法
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.inventoryCostBasisMethodology 库存计算方法 FIFO/COST_AVERAGE/SPECIFIC_ID
   */
  changeInventoryCostBasisMethodology: (entityId: string, data: object) => {
    return http.request('put', `entity/${entityId}/inventoryCostBasisMethodology`, { data })
  },
  /**
   * 运行inventory CostBasis规则
   * @param {string} entityId 主体id
   */
  runInventoryCostBasisRule: (entityId: string) => {
    return http.request('post', `entity/${entityId}/inventoryCostBasis/execute`)
  },
  /**
   * specificId规则列表
   * @param {string} entityId 主体id
   */
  getSpecificIdRuleList: (entityId: string) => {
    return http.request('get', `entity/${entityId}/specific/rule`)
  },
  /**
   * 创建specific规则
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string} data.name 规则名称
   * @property {Array} data.conditions 规则条件
   */
  createSpecificIdRule: (entityId: string, data: object) => {
    return http.request('post', `entity/${entityId}/specific/rule`, { data })
  },
  /**
   * 修改specific规则
   * @param {string} entityId 主体id
   * @param {string} specificRuleId 规则id
   * @param {Object} data
   * @property {string} data.name 规则名称
   * @property {Array} data.conditions 规则条件
   */
  updateSpecificIdRule: (entityId: string, specificRuleId: string, data: object) => {
    return http.request('put', `entity/${entityId}/specific/rule/${specificRuleId}`, { data })
  },
  /**
   * 删除specific规则
   * @param {string} entityId 主体id
   * @param {string} specificRuleId 规则id
   */
  deleteSpecificIdRule: (entityId: string, specificRuleId: string) => {
    return http.request('delete', `entity/${entityId}/specific/rule/${specificRuleId}`)
  },
  /**
   * 切换specific规则状态
   * @param {string} entityId 主体id
   * @param {string} specificRuleId 规则id
   * @param {Object} data
   * @property {boolean} data.enable 是否启用
   */
  switchSpecificIdRuleStatus: (entityId: string, specificRuleId: string, data: object) => {
    return http.request('post', `entity/${entityId}/specific/rule/${specificRuleId}/changeEnable`, { data })
  },
  /**
   * 修改specific规则排序
   * @param {string} entityId 主体id
   * @param {Object} data
   * @property {string[]} data.specificRuleIds 规则ids
   */
  updateSpecificIdRuleSort: (entityId: string, data: object) => {
    return http.request('post', `entity/${entityId}/specific/rule/sort`, { data })
  },
  /**
   * 执行计算对账调整
   * @param {string} entityId 主体id
   */
  executeRecalculateAdjustment: (entityId: string) => {
    return http.request('post', `/entity/${entityId}/recalculateAdjustment`)
  },
  /**
   * 导入 Lots
   * @param {string} entityId 主体id
   * @param {Object} data
   * @param {string} data.entityFileId
   */
  importLots: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/valuation/crypto/lot/import`, { data })
  },
  /**
   * 导入 inventory
   * @param {string} entityId 主体id
   * @param {Object} data
   * @param {string} data.entityFileId
   */
  importInventory: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/valuation/crypto/inventory/import`, { data })
  },
  /**
   * 获取Assets列表
   * @param {string} entityId 主体id
   */
  getAssetsTableList: (entityId: string, params?: Object) => {
    return http.request<ResponseData<AssetsListType>>('get', `/entity/${entityId}/asset`, { params })
  },
  /**
   * 添加Asset
   * @param {string} entityId 主体id
   * @param {Object} data 添加的Asset详细信息
   * @property {string} data.name Asset名称
   * @property {string} data.symbol Asset标识
   * @property {string} data.decimal Asset小数
   * @property {string} data.logo Asset Logo
   * @property {Array<Object>} data.contractList 合约列表
   * @property {number} data.priceProviderId 价格源ID
   *
   */
  addNewAssets: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/asset`, { data })
  },
  /**
   * 获取Asset详情
   * @param {string} entityId 主体id
   * @param {string} assetId Asset id
   */
  getAssetDetail: (entityId: string, assetId: string) => {
    return http.request<ResponseData<AssetsItemType>>('get', `/entity/${entityId}/asset/${assetId}`)
  },
  /**
   * 修改Asset
   * @param {string} entityId 主体id
   * @param {string} assetId Asset id
   * @param {Object} data 修改的Asset详细信息
   * @property {string} data.name Asset名称
   * @property {string} data.symbol Asset标识
   * @property {string} data.logo Asset Logo
   * @property {Array<Object>} data.contractList 合约列表
   * @property {number} data.priceProviderId 价格源ID
   */
  updateAsset: (entityId: string, assetId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/asset/${assetId}`, { data })
  },
  /**
   * 修改Assets详情页价格源
   * @param entityId 主体id
   * @param assetId 资产ID
   * @param data 更新价格源参数
   * @property { string } data.priceProviderId 价格源Id
   */
  updateAssetsPriceProvider: (entityId: string, assetId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/asset/${assetId}/updatePriceProvider`, { data })
  },
  /**
   * 上传Currency Logo
   * @param {string} entityId 主体id
   * @param {Object} data 上传的文件
   */
  uploadCurrencyLogo: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/currency/upload`, { data })
  },

  /**
   * 获取Price Provider价格源列表
   * @param {string} entityId
   * @param {Object} data 获取价格源列表参数
   * @property {string} data.page 当前展示列表页码
   * @property {string} data.limit 当前页展示数量
   */
  getPriceProviderList: (entityId: string, params?: Object) => {
    return http.request<ResponseData<PriceProviderType>>('get', `/entity/${entityId}/price/provider`, { params })
  },

  /**
   * 获取Price Provider价格源详情
   * @param {string} entityId 主体id
   * @param {string} priceProviderId 价格源ID
   * @returns Array
   */
  getPriceProviderDetail: (entityId: string, priceProviderId: string) => {
    return http.request<ResponseData<PriceProviderItemType>>(
      'get',
      `/entity/${entityId}/price/provider/${priceProviderId}`
    )
  },

  /**
   * 新增价格源
   * @param {string} entityId 主体id
   * @param {Object} data 新增价格源参数
   * @property { string } data.name 价格源名称
   * @property { string } data.type 价格源类型  目前仅支持'CSV',// 固定不能变
   * @property { string } data.timezone 时间戳时区
   * @property { string } data.quoteCurrency 币种
   */
  addPriceProviderInfo: (entityId: string, data: Object) => {
    return http.request('post', `/entity/${entityId}/price/provider`, { data })
  },

  /**
   * 上传价格源数据CSV文件
   * @param { string } entityId 主体Id
   * @param { string } priceProviderId 价格源Id
   * @param { object } data 保存价格源文件信息数据
   * @property { string } data.entityFileId CSV文件Id
   */
  savePriceProviderCSVData: (entityId: string, priceProviderId: number, data: Object) => {
    return http.request('post', `/entity/${entityId}/price/provider/${priceProviderId}/source`, { data })
  },

  /**
   * 获取合约损益调账结果列表
   * @param {string} entityId 主体id
   * @param {Object} data 请求列表参数
   * @property {number} data.page 当前展示列表页码
   * @property {number} data.limit 当前页展示数量
   * @property {string[]} data.origin 调账来源
   * @property {string} data.adjustmentNo 调账记录Id
   * @property {object} data.datetime 调账日期
   * @property {number[]} data.entityAccountIds 主体账户Id
   * @property {number[]} data.assetIds 资产Id
   * @property {string[]} data.positionSide 持仓情况
   * @property {string[]} data.currencyList 币种列表
   * @property {object} data.amount 金额
   * @property {object} data.amountFC 金额外币
   */
  getFutureAdjustmentList: (entityId: string, data: object) => {
    return http.request<ResponseData<FutureAdjustmentListType>>(
      'post',
      `/entity/${entityId}/valuation/future/adjustment`,
      { data }
    )
  },

  /**
   * 获取期权损益调账结果列表
   * @param {string} entityId 主体id
   * @param {Object} data 请求列表参数
   * @property {number} data.page 当前展示列表页码
   * @property {number} data.limit 当前页展示数量
   * @property {string[]} data.origin 调账来源
   * @property {string} data.adjustmentNo 调账记录Id
   * @property {object} data.datetime 调账日期
   * @property {number[]} data.entityAccountIds 主体账户Id
   * @property {number[]} data.assetIds 资产Id
   * @property {string[]} data.positionSide 持仓情况
   * @property {string[]} data.currencyList 币种列表
   * @property {object} data.amount 金额
   * @property {object} data.amountFC 金额外币
   */
  getOptionsAdjustmentList: (entityId: string, data: object) => {
    return http.request<ResponseData<OptionsAdjustmentListType>>(
      'post',
      `/entity/${entityId}/valuation/option/adjustment`,
      { data }
    )
  },

  /**
   * 获取验证者调账结果列表
   * @param {string} entityId 主体id
   * @param {Object} params 请求列表参数
   * @property {number} params.page 当前展示列表页码
   * @property {number} params.limit 当前页展示数量
   * @property {string} params.sortField 排序字段
   * @property {string} params.sortDirection 排序方向
   * @param {Object} data 筛选项参数
   * @property {string} data.adjustmentNo 调账记录Id
   * @property {object} data.datetime 调账日期
   * @property {number[]} data.entityAccountIds 主体账户Id
   * @property {number[]} data.assetIds 资产Id
   * @property {object} data.amount 金额
   * @property {object} data.amountFC 金额外币
   */
  getValidatorAdjustmentList: (entityId: string, params: object, data: object) => {
    return http.request<ResponseData<ValidatorAdjustmentListType>>(
      'post',
      `/entity/${entityId}/valuation/validator/adjustment`,
      { params, data }
    )
  },

  /**
   * 获取合约损益列表
   * @param {string} entityId 主体id
   * @param {Object} params 请求列表参数
   * @property {number} params.page 当前展示列表页码
   * @property {number} params.limit 当前页展示数量
   */
  getFutureFactList: (entityId: string, params: object) => {
    return http.request<ResponseData<FutureRawDataListType>>('get', `/entity/${entityId}/valuation/future/fact`, {
      params
    })
  },

  /**
   * 获取合约损益列表-ending弹窗
   * @param {string} entityId 主体id
   * @param {Object} data 请求列表参数
   * @property {number} data.page 当前展示列表页码
   * @property {number} data.limit 当前页展示数量
   * @property {string} data.beginningDate 开始时间
   * @property {string} data.endingDate 开始时间
   * @property {string} data.origin 来源
   * @property {string} data.assetId 资产ID
   * @property {string} data.entityAccountId 主体账户ID
   */
  getFutureFactEndingList(entityId: string, data: object) {
    return http.request<ResponseData<FutureRawDataListType>>(
      'post',
      `/entity/${entityId}/valuation/future/fact/ending`,
      { data }
    )
  },

  /**
   * 获取合约损益列表-realized弹窗
   * @param {string} entityId 主体id
   * @param {Object} data 请求列表参数
   * @property {number} data.page 当前展示列表页码
   * @property {number} data.limit 当前页展示数量
   * @property {string} data.beginningDate 开始时间
   * @property {string} data.endingDate 开始时间
   * @property {string} data.origin 来源
   * @property {string} data.assetId 资产ID
   * @property {string} data.entityAccountId 主体账户ID
   */
  getFutureFactRealizedList(entityId: string, data: object) {
    return http.request<ResponseData<FutureRawDataListType>>(
      'post',
      `/entity/${entityId}/valuation/future/fact/realized`,
      { data }
    )
  },

  /**
   * 获取期权损益列表
   * @param {string} entityId 主体id
   * @param {Object} params 请求列表参数
   * @property {number} params.page 当前展示列表页码
   * @property {number} params.limit 当前页展示数量
   */
  getOptionsFactList: (entityId: string, params: object) => {
    return http.request<ResponseData<OptionsRawDataListType>>('get', `/entity/${entityId}/valuation/option/fact`, {
      params
    })
  },

  /**
   * 获取期权损益列表-ending弹窗
   * @param {string} entityId 主体id
   * @param {Object} data 请求列表参数
   * @property {number} data.page 当前展示列表页码
   * @property {number} data.limit 当前页展示数量
   * @property {string} data.beginningDate 开始时间
   * @property {string} data.endingDate 开始时间
   * @property {string} data.origin 来源
   * @property {string} data.assetId 资产ID
   * @property {string} data.entityAccountId 主体账户ID
   */
  getOptionsFactEndingList(entityId: string, data: object) {
    return http.request<ResponseData<OptionsRawDataListType>>(
      'post',
      `/entity/${entityId}/valuation/option/fact/ending`,
      { data }
    )
  },

  /**
   * 获取期权损益列表-realized弹窗
   * @param {string} entityId 主体id
   * @param {Object} data 请求列表参数
   * @property {number} data.page 当前展示列表页码
   * @property {number} data.limit 当前页展示数量
   * @property {string} data.beginningDate 开始时间
   * @property {string} data.endingDate 开始时间
   * @property {string} data.origin 来源
   * @property {string} data.assetId 资产ID
   * @property {string} data.entityAccountId 主体账户ID
   */
  getOptionsFactRealizedList(entityId: string, data: object) {
    return http.request<ResponseData<OptionsRawDataListType>>(
      'post',
      `/entity/${entityId}/valuation/option/fact/realized`,
      { data }
    )
  },

  /**
   * 获取验证者损益列表
   * @param {string} entityId 主体id
   * @param {Object} params 请求列表参数
   * @property {number} params.page 当前展示列表页码
   * @property {number} params.limit 当前页展示数量
   * @param {object} data 过滤参数
   * @property { string } data.entityAccountIds 账户
   * @property { string } data.datetime 时间
   * @property { string } data.referenceNo 索引号
   * @property { string } data.type 类型
   * @property { string } data.amount 金额
   */
  getValidatorFactList: (entityId: string, params: object, data: object) => {
    return http.request<ResponseData<ValidatorRawDataListType>>(
      'post',
      `/entity/${entityId}/valuation/validator/fact`,
      {
        params,
        data
      }
    )
  },

  /**
   * 获取验证者损益列表-ending弹窗
   * @param {string} entityId 主体id
   * @param {Object} params 请求列表参数
   * @property {number} params.page 当前展示列表页码
   * @property {number} params.limit 当前页展示数量
   * @param {Object} data 请求列表参数
   * @property {string} data.beginningDate 开始时间
   * @property {string} data.endingDate 开始时间
   * @property {string} data.assetId 资产ID
   * @property {string} data.entityAccountId 主体账户ID
   */
  getValidatorFactEndingList(entityId: string, params: object, data: object) {
    return http.request<ResponseData<ValidatorRawDataListType>>(
      'post',
      `/entity/${entityId}/valuation/validator/fact/ending`,
      { params, data }
    )
  },

  /**
   * 获取验证者损益列表-withdraw弹窗
   * @param {string} entityId 主体id
   * @param {Object} params 请求列表参数
   * @property {number} params.page 当前展示列表页码
   * @property {number} params.limit 当前页展示数量
   * @param {Object} data 请求列表参数
   * @property {string} data.beginningDate 开始时间
   * @property {string} data.endingDate 开始时间
   * @property {string} data.assetId 资产ID
   * @property {string} data.entityAccountId 主体账户ID
   */
  getValidatorFactWithDrawList(entityId: string, params: object, data: object) {
    return http.request<ResponseData<ValidatorRawDataListType>>(
      'post',
      `/entity/${entityId}/valuation/validator/fact/withdraw`,
      { params, data }
    )
  },

  /**
   * 获取验证者损益列表-deposit弹窗
   * @param {string} entityId 主体id
   * @param {Object} params 请求列表参数
   * @property {number} params.page 当前展示列表页码
   * @property {number} params.limit 当前页展示数量
   * @param {Object} data 请求列表参数
   * @property {string} data.beginningDate 开始时间
   * @property {string} data.endingDate 开始时间
   * @property {string} data.assetId 资产ID
   * @property {string} data.entityAccountId 主体账户ID
   */
  getValidatorFactDepositList(entityId: string, params: object, data: object) {
    return http.request<ResponseData<ValidatorRawDataListType>>(
      'post',
      `/entity/${entityId}/valuation/validator/fact/deposit`,
      { params, data }
    )
  },

  /**
   * 获取Asset 中所有 Future/Options 类资产
   * @param {string} entityId 主体id
   * @param {Object} params 请求列表参数
   * @property {number} params.page 当前展示列表页码
   * @property {number} params.limit 当前页展示数量
   */
  getDerivativeList: (entityId: string, params: object) => {
    return http.request<ResponseData<DerivativeItemType[]>>('get', `/entity/${entityId}/derivative`, { params })
  },

  /**
   * 设置调账周期
   * @param {string} entityId 主体id
   * @param {Object} data 请求列表参数
   * @property {string} data.adjustmentFrequency 调账周期
   */
  setAdjustmentFrequency: (entityId: string, data: Object) => {
    return http.request('put', `/entity/${entityId}/adjustmentFrequency`, { data })
  },

  /**
   * 设置Broker Inventory 核算资产
   * @param {string} entityId 主体id
   * @param {Object} data 请求列表参数
   * @property {string[]} data.brokerInventorySelection ['CRYPTO', 'FOREIGN_CURRENCY']
   */
  setBrokerInventorySelection: (entityId: string, data: Object) => {
    return http.request('post', `/entity/${entityId}/valuation/setBrokerInventorySelection`, { data })
  },

  /**
   * 获取Derivative Adjustment自动化规则列表
   * @param {string} entityId 主体id
   * @param {object} params
   * @property {number} params.page
   * @property {number} params.limit
   */
  getDerivativeAdjustmentAutomationRuleList: (entityId: string, params: object) => {
    return http.request<ResponseData<AutomationRuleListType>>(
      'get',
      `/entity/${entityId}/valuation/derivativeAdjustment/automationRule`,
      { params }
    )
  },

  /**
   * 添加Derivative Adjustment自动化规则
   * @param {string} entityId 主体id
   * @param {object} data
   * @property {string} data.name 规则名称
   * @property {string} data.derivativeType FUTURE/OPTION
   * @property {Array} data.conditions 规则
   * @property {string} data.journalTypeId journalType
   * @property {Array} data.entryActions entryActions
   */
  addDerivativeAdjustmentAutomationRule: (entityId: string, data: object) => {
    return http.request('post', `/entity/${entityId}/valuation/derivativeAdjustment/automationRule`, { data })
  },

  /**
   * 修改Derivative Adjustment自动化规则
   * @param {string} entityId 主体id
   * @param {string} derivativeAdjustmentAutomationRuleId 规则id
   * @param {object} data
   * @property {string} data.name 规则名称
   * @property {string} data.derivativeType FUTURE/OPTION
   * @property {Array} data.conditions 规则
   * @property {string} data.journalTypeId journalType
   * @property {Array} data.entryActions entryActions
   */
  editDerivativeAdjustmentAutomationRule: (
    entityId: string,
    derivativeAdjustmentAutomationRuleId: string,
    data: object
  ) => {
    return http.request(
      'put',
      `/entity/${entityId}/valuation/derivativeAdjustment/automationRule/${derivativeAdjustmentAutomationRuleId}`,
      { data }
    )
  },

  /**
   * 删除Derivative Adjustment自动化规则
   * @param {string} entityId 主体id
   * @param {string} derivativeAdjustmentAutomationRuleId 规则id
   */
  deleteDerivativeAdjustmentAutomationRule: (entityId: string, derivativeAdjustmentAutomationRuleId: string) => {
    return http.request(
      'delete',
      `/entity/${entityId}/valuation/derivativeAdjustment/automationRule/${derivativeAdjustmentAutomationRuleId}`
    )
  },

  /**
   * Derivative Adjustment规则启用
   * @param {string} entityId 主体id
   * @param {string} derivativeAdjustmentAutomationRuleId 规则id
   * @param {object} data
   * @property {boolean} data.enabled 是否启用
   */
  switchDerivativeAdjustmentAutomationRuleStatus: (
    entityId: string,
    derivativeAdjustmentAutomationRuleId: string,
    data: object
  ) => {
    return http.request(
      'put',
      `/entity/${entityId}/valuation/derivativeAdjustment/automationRule/${derivativeAdjustmentAutomationRuleId}/status`,
      { data }
    )
  },

  /**
   * 执行所有Derivative Adjustment自动化规则
   * @param {string} entityId 主体id
   */
  executeAllDerivativeAdjustmentAutomationRule: (entityId: string) => {
    return http.request('post', `/entity/${entityId}/valuation/derivativeAdjustment/automationRule/execute`)
  },

  /**
   * 修改Derivative Adjustment的rule优先级
   * @param {string} entityId 主体id
   * @param {Array} data [{derivativeAdjustmentAutomationRuleId: '5123'}, {derivativeAdjustmentAutomationRuleId: '5233'}]
   */
  editDerivativeAdjustmentAutomationRulePriority: (entityId: string, data: object) => {
    return http.request('put', `/entity/${entityId}/valuation/derivativeAdjustment/automationRule/priority`, { data })
  }
}
